// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import config from '../config';
import { CodeHostType } from '../models/CodeHost';
import AppService from '../models/services/AppService';
import Session from '../models/Session';
import ApiClient from './ApiClient';

export default class AppClient implements AppService {
    client: ApiClient;

    constructor(session: Session) {
        this.client = new ApiClient({ baseUrl: config.endpoints.app, session });
    }

    async getAppDetails(codeHostType: CodeHostType) {
        const response = await this.client.get(codeHostType);

        if (!response.ok) {
            throw new Error('Error trying to get app details');
        }

        return response.json();
    }
}
