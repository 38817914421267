// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { ReactNode, useMemo } from 'react';
import AppClient from '../clients/AppClient';
import OrganizationClient from '../clients/OrganizationClient';
import ServicesContext from '../contexts/ServicesContext';
import { useSessionContext } from '../hooks/useSessionContext';
import Services from '../models/Services';

interface ServicesContextProps {
    children: ReactNode;
}

export default function ServicesController(props: ServicesContextProps) {
    const { children } = props;
    const sessionContext = useSessionContext();

    const apolloClient = useMemo(
        () => new ApolloClient({
            uri: process.env.REACT_APP_NEXUS_GRAPHQL_ENDPOINT as string,
            cache: new InMemoryCache(),
            headers: {
                'Authorization': `Bearer ${sessionContext.loggedIn ? sessionContext?.accessToken : ''}`,
                // TODO: Remove this header after fixing https://github.com/reviewpad/roadmap/issues/188
                'Accept': 'application/vnd.github.hawkgirl-preview+json',
            }
        }),
        [sessionContext],
    );

    const services: Services = useMemo(
        () => ({
            app: new AppClient(sessionContext),
            organization: new OrganizationClient(sessionContext),
        }),
        [sessionContext],
    );

    return (
        <ServicesContext.Provider value={services}>
            <ApolloProvider client={apolloClient}>
                {children}
            </ApolloProvider>
        </ServicesContext.Provider>
    );
}
