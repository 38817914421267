// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Box, Card, CardContent, ListItem, Typography } from '@mui/material';
import styles from '../styles/IssueParameterFacet.module.css';
import LinearProgressWithLabel from './LinearProgressWithLabel';

interface IssueParameterFacetProps {
    label: string;
    value: string;
    totalIssues: number;
    items: Map<string, number>;
}

export default function IssueParameterFacet(props: IssueParameterFacetProps) {
    const { label, totalIssues, items } = props;

    return (
        <Card>
            <CardContent>
                <Typography variant="subtitle1" component="div" sx={{ fontWeight: 600, pb: 2 }}>
                    {label}
                </Typography>
                <div className={styles.items}>
                    {Array.from(items.entries()).map(([item, quantity]) => (
                        <ListItem key={item} className={styles.facetedFilterOption}>
                            <Typography variant="body2" color="text.secondary" sx={{ wordBreak: 'break-word', minWidth: '50%' }}>
                                {item}
                            </Typography>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabel value={quantity} percentage={(quantity / totalIssues) * 100} />
                            </Box>
                        </ListItem>
                    ))}
                </div>
            </CardContent>
        </Card>
    );
}
