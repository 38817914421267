// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { plural } from './text';

export type Details = {
    slug: string;
    title: string;
    value: number;
}[];

// Check Details: https://github.com/reviewpad/checker/issues/35
export function formatIssueDetails(details: Details) {
    if (details.length === 0) {
        return '';
    }

    switch (details[0].slug) {
        case 'duration-of-staleness':
        case 'lifespan':
            return details[0].value === 0 ? `less than a day` : `${details[0].value} ${plural(details[0].value, 'day')}`;
        case 'lines-count':
            return details[0].value === 0 ? `less than a hour` : `${details[0].value} ${plural(details[0].value, 'line')}`;
        case 'wait-time':
            return `${details[0].value} ${plural(details[0].value, 'hour')}`;
        case 'comment-count':
            return `${details[0].value} ${plural(details[0].value, 'comment')}`;
        case 'iteration-count':
            return `${details[0].value} ${plural(details[0].value, 'iteration')}`;
        case 'commit-count':
            return `${details[0].value} ${plural(details[0].value, 'commit')}`;
        case 'instability-rate':
        case 'internal-code-churn-rate':
        case 'churn-rate':
            return `${details[0].value} %`;
        default:
            return details[0].value;
    }
}
