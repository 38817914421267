// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import config from '../config';
import OrganizationService from '../models/services/OrganizationService';
import Session from '../models/Session';
import Subscription from '../models/Subscription';
import ApiClient from './ApiClient';

export default class OrganizationClient implements OrganizationService {
    client: ApiClient;

    constructor(session: Session) {
        this.client = new ApiClient({ baseUrl: config.endpoints.organization, session });
    }

    async getIssues(organizationId: string) {
        const response = await this.client.get(`${organizationId}/issues`);

        if (!response.ok) {
            throw new Error('Error trying to get organization issues');
        }

        return (await response.json()).issues;
    }

    async getMembers(organizationId: string, organizationName: string, organizationType: string, userCodeHostId: string) {
        const params = new URLSearchParams({
            user_code_host_id: userCodeHostId,
            organization_type: organizationType,
            organization_name: organizationName,
        });

        const response = await this.client.get(`${organizationId}/members?${params}`);

        if (!response.ok) {
            throw new Error('Error trying to get organization members');
        }

        return response.json();
    }

    async getOrganizations() {
        const response = await this.client.get();

        if (!response.ok) {
            throw new Error('Error trying to get organizations');
        }

        return response.json();
    }

    async getRepositories(organizationId: string, organizationName: string, organizationType: string, userCodeHostId: string, installationId: string) {
        const params = new URLSearchParams({
            user_code_host_id: userCodeHostId,
            organization_name: organizationName,
            organization_type: organizationType,
            installation_id: installationId,
        });

        const response = await this.client.get(`${organizationId}/repository?${params}`);

        if (!response.ok) {
            throw new Error('Error trying to get organization repositories');
        }

        return response.json();
    }

    async getSubscription(organizationId: string) {
        const response = await this.client.get(`${organizationId}/subscription`);

        if (response.status === 404) {
            return;
        }

        if (!response.ok) {
            throw new Error('Error trying to get organization subscription');
        }

        return response.json();
    }

    async getSubscriptionCheckoutUrl(organizationId: string, organizationName: string, userCodeHostId: string, totalSeats: number) {
        const params = new URLSearchParams({
            organization_name: organizationName,
            user_code_host_id: userCodeHostId,
            number_of_seats: totalSeats.toString(),
        });

        const response = await this.client.get(`${organizationId}/subscription/checkout/start?${params}`);

        if (!response.ok) {
            throw new Error('Error trying to get subscription payment url');
        }

        const { url } = await response.json();

        return url;
    }

    async startSubscriptionTrial(organizationId: string) {
        const response = await this.client.post(`${organizationId}/subscription/trial`);

        if (!response.ok) {
            throw new Error('Error trying to start subscription trial');
        }

        return response.json();
    }

    async updateSubscription(organizationId: string, updatedSubscription: Partial<Subscription>) {
        const response = await this.client.patch(`${organizationId}/subscription`, updatedSubscription);

        if (!response.ok) {
            throw new Error('Error trying to update subscription');
        }

        return response.json();
    }
}
