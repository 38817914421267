// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import Subscription, { SubscriptionTeam, SubscriptionTeamPaid } from '../models/Subscription';

export function isSubscriptionTeamTrial(subscription: Subscription): subscription is SubscriptionTeam {
    return subscription.subscription_type === 'team' && !Boolean((subscription as SubscriptionTeamPaid).subscription_start_date);
}

export function isSubscriptionTeamPaid(subscription: Subscription): subscription is SubscriptionTeamPaid {
    return subscription.subscription_type === 'team' && Boolean((subscription as SubscriptionTeamPaid).subscription_start_date);
}
