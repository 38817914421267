// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

export const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
] as const;

export function getReadableDate(date: string): string;
export function getReadableDate(date: Date): string;
export function getReadableDate(date: string | Date) {
    const mappedDate = date instanceof Date ? date : new Date(date);

    return `${month[mappedDate.getMonth()]} ${mappedDate.getDate()}, ${mappedDate.getFullYear()}`;
};

export function getReadableDateInDays(date: string | Date) {
    const mappedDate = date instanceof Date ? date : new Date(date);
    const today = new Date();
    const diffTime = today.getTime() - mappedDate.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

    return diffDays;
};

export function getDateDifference(dateA: Date, dateB: Date) {
    const diffTime = dateB.getTime() - dateA.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

    return diffDays;
}

export function addDays(date: string, daysToAdd: number): Date;
export function addDays(date: Date, daysToAdd: number): Date;
export function addDays(date: string | Date, daysToAdd: number) {
    const mappedDate = date instanceof Date ? date : new Date(date);
    mappedDate.setDate(mappedDate.getDate() + daysToAdd);

    return mappedDate;
};
