// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import React from 'react';
import Services from '../models/Services';

export default React.createContext<Services>(new Proxy({}, {
    get() {
        throw new Error('Trying to access services without an ancestor ServiceContext');
    }
}) as any);
