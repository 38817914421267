// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { SubscriptionTeam } from '../models/Subscription';
import { isSubscriptionTeamPaid } from '../utils/subscription';

interface TeamPlanSummaryProps {
    subscription: SubscriptionTeam;
}

export default function TeamPlanSummary(props: TeamPlanSummaryProps) {
    const { subscription } = props;
    return (
        <Card variant="outlined">
            <CardHeader title={<strong>Team Plan</strong>} />
            <CardContent sx={{ paddingTop: 0, lineHeight: 1.5 }}>
                <ul>
                    <li><Typography>Unlimited public/private repositories</Typography></li>
                    <li><Typography>Unlimited number of contributors on public repositories</Typography></li>
                    <li><Typography>All-inclusive Reviewpad features</Typography></li>
                    <ul>
                        <li><Typography>AI assistant for code reviewers</Typography></li>
                        <li><Typography>Advanced code reviewer assignments</Typography></li>
                        <li><Typography>Advanced merge policy</Typography></li>
                        <li><Typography>Automated pull request workflows</Typography></li>
                    </ul>
                </ul>
                {!isSubscriptionTeamPaid(subscription) && (
                    <Typography variant="h6" sx={{ marginTop: 4 }}>
                        <strong>$8</strong> per seat / month on private repositories
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
}
