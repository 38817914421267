// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Button } from '@mui/material';
import React, { ReactNode } from 'react';
import UnexpectedError from './UnexpectedError';

export default class ErrorHandler extends React.PureComponent<{ children: ReactNode }, { error: boolean }> {
    state = {
        error: false,
    };

    componentDidCatch() {
        this.setState({ error: true });
    }

    render() {
        if (this.state.error) {
            return (
                <UnexpectedError>
                    <Button
                        variant="contained"
                        onClick={() => location.reload()}
                    >
                        Try again!
                    </Button>
                </UnexpectedError>
            );
        }

        return this.props.children;
    }
}
