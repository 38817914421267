// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import BugReportIcon from '@mui/icons-material/BugReport';
import InboxIcon from '@mui/icons-material/Inbox';
import StarIcon from '@mui/icons-material/Star';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Sidebar.module.css';

interface Option {
    name: string;
    path: string;
    icon: React.ReactNode;
}

const options: Option[] = [
    { name: 'Issues', path: '/issues', icon: <BugReportIcon /> },
    { name: 'Plan', path: '/plan', icon: <StarIcon /> },
];

export default function Sidebar() {
    const currentPath = window.location.pathname;
    const params = new URLSearchParams(window.location.search);

    return (
        <Paper className={styles.container} component="nav" square elevation={0}>
            <List className={styles.options}>
                {options.map((option) => {
                    const isSelected = option.path === currentPath;
                    return (
                        <ListItem key={option.name}>
                            <ListItemButton
                                component={Link}
                                className={clsx(styles.listItemButton, isSelected && styles.listItemSelectedButton)}
                                to={`${option.path}?${params}`}
                                selected={isSelected}
                            >
                                <ListItemIcon className={clsx(isSelected && styles.listItemSelectedIcon)}>
                                    {option.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={option.name}
                                    primaryTypographyProps={{ className: clsx(isSelected && styles.listItemSelectedText) }}
                                />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </Paper >
    );
}
