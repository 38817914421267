// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Button, Card, CardContent, CardHeader, TextField, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { SubscriptionTeam, SubscriptionTeamPaid, SubscriptionTeamTrial } from '../models/Subscription';
import styles from '../styles/TeamPlanStatus.module.css';
import { getDateDifference, getReadableDate } from '../utils/date';
import { plural } from '../utils/text';

interface PurchaseInfoProps {
    isUserAdmin: boolean;
    defaultTotalSeats: number;
    onSubscriptionCheckout(totalSeats: number): void;
}

function PurchaseInfo(props: PurchaseInfoProps) {
    const { isUserAdmin, defaultTotalSeats, onSubscriptionCheckout } = props;
    const [totalSeats, setTotalSeats] = useState(defaultTotalSeats);

    const handleTotalSeatsChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setTotalSeats(Number(event.target.value));
        },
        [],
    );

    const handleSubscriptionCheckout = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            onSubscriptionCheckout(totalSeats);
        },
        [onSubscriptionCheckout, totalSeats],
    );

    return (
        <div className={styles.purchaseInfo}>
            <form className={styles.purchaseAction} onSubmit={handleSubscriptionCheckout}>
                <Typography>
                    Activate the Team Plan for one year and{' '}
                </Typography>
                <TextField
                    type="number"
                    value={totalSeats || ''}
                    onChange={handleTotalSeatsChange}
                    className={styles.seatsInput}
                    disabled={!isUserAdmin}
                    required
                    InputProps={{
                        className: styles.seatsInputField,
                        inputProps: {
                            min: 1,
                        },
                    }}
                    sx={{
                        '& .MuiInputBase-input': {
                            color: 'black',
                        },
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)',
                            borderColor: 'rgba(0, 0, 0, 0.38)',
                        },
                    }}
                />
                <Typography>
                    {'seats ($8 per seat / month)'}
                </Typography>
                <Tooltip
                    title={!isUserAdmin && (
                        <Typography variant='body2' sx={{ textAlign: 'center' }}>
                            You need administrative privileges to trigger this action.
                        </Typography>
                    )}
                    arrow
                >
                    <span>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={!isUserAdmin}
                        >
                            Submit
                        </Button>
                    </span>
                </Tooltip>
            </form>
            <Typography sx={{ marginTop: 2 }}>
                Each author of Pull Requests requires a separate seat. Therefore, if you have 30 members in your organization, but only 10 of them are creating Pull Requests, you only need to purchase 10 seats.
            </Typography>
        </div >
    );
}

interface TeamPlanTrialProps {
    isUserAdmin: boolean;
    subscription: SubscriptionTeamTrial;
    defaultTotalSeats: number;
    onSubscriptionCheckout(totalSeats: number): void;
}

function TeamPlanTrial(props: TeamPlanTrialProps) {
    const { isUserAdmin, subscription, defaultTotalSeats } = props;
    const { onSubscriptionCheckout } = props;
    const trialEndDate = new Date(subscription.trial_period_end_date);
    const trialDaysLeft = getDateDifference(new Date(), trialEndDate);
    const isTrialExpired = trialEndDate < new Date();

    return (
        <Card className={isTrialExpired ? styles.teamTrialPlanExpired : styles.teamTrialPlan}>
            <CardHeader title={isTrialExpired ? 'Trial Period Is Over' : 'Ongoing Trial Period'} />
            <CardContent>
                {!isTrialExpired && (
                    <Typography>
                        Experience the full potential of Reviewpad during the Trial Period. After the trial period ends, in {trialDaysLeft} {plural(trialDaysLeft, 'day')}, Reviewpad will continue to be available exclusively for use on public repositories.
                    </Typography>
                )}
                {isTrialExpired && (
                    <Typography>
                        The Trial Period has ended. Reviewpad is now available for public repositories only.
                    </Typography>
                )}
                <PurchaseInfo
                    isUserAdmin={isUserAdmin}
                    defaultTotalSeats={defaultTotalSeats}
                    onSubscriptionCheckout={onSubscriptionCheckout}
                />
            </CardContent>
        </Card>
    );
}

interface TeamPlanPaidProps extends TeamPlanTrialProps {
    subscription: SubscriptionTeamPaid;
    onAutoRenewChange(isAutoRenewEnabled: boolean): void;
}

function TeamPlanPaid(props: TeamPlanPaidProps) {
    const { isUserAdmin, subscription, defaultTotalSeats } = props;
    const { onSubscriptionCheckout, onAutoRenewChange } = props;
    const subscriptionEndDate = new Date(subscription.subscription_end_date);
    const isSubscriptionExpired = subscriptionEndDate < new Date();
    const pricePerSeat = (subscription.yearly_bill / subscription.number_of_seats).toFixed(2);

    const handleAutoRenewChange = useCallback(
        () => onAutoRenewChange(!subscription.auto_renew),
        [onAutoRenewChange, subscription.auto_renew],
    );

    return (
        <Card className={isSubscriptionExpired ? styles.teamPlanExpired : styles.teamPlan} elevation={0}>
            <CardHeader title={isSubscriptionExpired ? 'Team Plan Has Expired' : 'Team Plan Activated'} />
            <CardContent>
                {isSubscriptionExpired && (
                    <Typography>
                        The Team Plan has expired. Reviewpad is now available for public repositories only.
                    </Typography>
                )}
                {isSubscriptionExpired && (
                    <PurchaseInfo
                        isUserAdmin={isUserAdmin}
                        defaultTotalSeats={defaultTotalSeats}
                        onSubscriptionCheckout={onSubscriptionCheckout}
                    />
                )}
                {!isSubscriptionExpired && (
                    <>
                        <table className={styles.subscriptionInfo}>
                            <tbody>
                                <tr>
                                    <td><Typography>Seats:</Typography></td>
                                    <td><Typography><strong>{subscription.number_of_seats}</strong></Typography></td>
                                </tr>
                                <tr>
                                    <td><Typography>Yearly bill (with taxes):</Typography></td>
                                    <td><Typography><strong>$ {subscription.yearly_bill}</strong> {`($ ${pricePerSeat} / seat)`}</Typography></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={styles.subscriptionRenewal}>
                            <Typography>
                                The Team Plan will automatically {subscription.auto_renew ? 'renew' : 'expire'} on {getReadableDate(subscriptionEndDate)}
                            </Typography>
                            {/* Some users have not subscribed through Stripe and so they are paying the subscription by other means, such as, bank transfer. */}
                            {/* Taking this into account, there should only be an auto renewal mechanism on plans subscribed through Stripe. */}
                            {subscription.subscription_stripe_id && (
                                <Tooltip
                                    title={!isUserAdmin && (
                                        <Typography variant='body2' sx={{ textAlign: 'center' }}>
                                            You need administrative privileges to trigger this action.
                                        </Typography>
                                    )}
                                    arrow
                                >
                                    <Button
                                        variant="contained"
                                        onClick={handleAutoRenewChange}
                                        disabled={!isUserAdmin}
                                        disableElevation
                                    >
                                        {subscription.auto_renew ? 'Cancel' : 'Activate'} Automatic Renewal
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    </>
                )}
            </CardContent>
        </Card>
    );
}

interface PlanTeamProps extends Omit<TeamPlanPaidProps, 'subscription'> {
    subscription: SubscriptionTeam;
}

export default function TeamPlanStatus(props: PlanTeamProps) {
    const { subscription } = props;
    const isOnTrial = !(subscription as SubscriptionTeamPaid).subscription_end_date;

    return isOnTrial
        ? <TeamPlanTrial {...props} subscription={subscription as SubscriptionTeamTrial} />
        : <TeamPlanPaid {...props} subscription={subscription as SubscriptionTeamPaid} />;
}
