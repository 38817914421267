// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from '../styles/Subtitle.module.css';

interface SubtitleProps {
    children: ReactNode;
    className?: string;
}

export default function Subtitle(props: SubtitleProps) {
    const { className, children } = props;
    return (
        <Typography className={clsx(styles.subtitle, className)} variant="h6">
            {children}
        </Typography>
    );
}
