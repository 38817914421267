// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Alert, Button, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useNotificationContext } from '../hooks/notificationHook';
import { useOrganizationService } from '../hooks/serviceHooks';
import useAsyncEffect from '../hooks/useAsyncEffect';
import Organization from '../models/Organization';
import Subscription from '../models/Subscription';
import styles from '../styles/PlanManagement.module.css';
import { isSubscriptionTeamPaid, isSubscriptionTeamTrial } from '../utils/subscription';
import Loading from './Loading';
import OpenSourcePlanCard from './OpenSourcePlanCard';
import PageContainer from './PageContainer';
import TeamPlanEdition from './TeamPlanEdition';
import Title from './Title';
import UnexpectedError from './UnexpectedError';

interface PlanManagementProps {
    organization: Organization;
}

export default function PlanManagement(props: PlanManagementProps) {
    const { organization } = props;
    const notification = useNotificationContext();
    const organizationService = useOrganizationService();
    const [error, setError] = useState<Error | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const [subscription, setSubscription] = useState<Subscription>();
    const [totalSeats, setTotalSeats] = useState(1);
    const isUserAdmin = organization.role === 'admin';

    const handleSubscriptionCheckout = useCallback(
        async (totalSeats: number) => {
            try {
                const checkoutUrl = await organizationService.getSubscriptionCheckoutUrl(organization.id, organization.name, organization.user_code_host_id, totalSeats);
                window.location.href = checkoutUrl;
            } catch (error) {
                notification.error('Error trying to start subscription checkout');
            }
        },
        [notification, organization.id, organization.name, organization.user_code_host_id, organizationService],
    );

    const handleSubscriptionAutoRenewChange = useCallback(
        async (autoRenew: boolean) => {
            try {
                const updatedSubscription = await organizationService.updateSubscription(organization.id, { auto_renew: autoRenew });
                setSubscription(updatedSubscription);

                notification.success(`Subscription auto renewal was ${autoRenew ? 'activated' : 'canceled'}`);
            } catch (error) {
                notification.error('Error trying to update subscription auto renew');
            }
        },
        [notification, organization.id, organizationService],
    );

    useAsyncEffect(
        async () => {
            try {
                setIsLoading(true);

                const subscription = await organizationService.getSubscription(organization.id);

                if (subscription) {
                    const isOnTrial = isSubscriptionTeamTrial(subscription);
                    const isSubscriptionExpired = isSubscriptionTeamPaid(subscription) && new Date(subscription.subscription_end_date) < new Date();

                    if (isOnTrial || isSubscriptionExpired) {
                        const organizationMembers = await organizationService.getMembers(organization.id, organization.name, organization.type, organization.user_code_host_id);
                        setTotalSeats(organizationMembers.length);
                    }

                    setSubscription(subscription);
                    return;
                }
            } catch (error) {
                console.error(error);
                setError(error as Error);
            } finally {
                setIsLoading(false);
            }
        },
        [organization],
    );

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <UnexpectedError />;
    }

    return (
        <PageContainer className={styles.container}>
            <Title>Current Plan</Title>
            {subscription && !isUserAdmin && (
                <Alert severity='info' color='error' sx={{ alignItems: 'center', fontSize: '18px' }}>
                    <strong>You are not an admin of this organization. Only admins can manage the subscription plan.</strong>
                </Alert>
            )}
            {subscription?.subscription_type === 'open_source' && <OpenSourcePlanCard />}
            {subscription?.subscription_type === 'team' && (
                <TeamPlanEdition
                    isUserAdmin={isUserAdmin}
                    subscription={subscription}
                    defaultTotalSeats={totalSeats}
                    onSubscriptionCheckout={handleSubscriptionCheckout}
                    onAutoRenewChange={handleSubscriptionAutoRenewChange}
                />
            )}
            <Typography variant="body1" sx={{ marginTop: 2 }}>
                Have a question?{' '}
                <Button
                    variant="contained"
                    color="secondary"
                    href="https://contact.reviewpad.com/meetings/adriano-martins/account-management"
                    target="_blank"
                    disableElevation
                >
                    Get in touch
                </Button>
                {' '}with our team at your convenience.
            </Typography>
        </PageContainer>
    );
}
