// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useSessionContext } from '../hooks/useSessionContext';

interface ProtectedPageProps {
    children: ReactNode;
}

export default function ProtectedPage(props: ProtectedPageProps) {
    const { children } = props;
    const sessionContext = useSessionContext();

    if (sessionContext.loggedIn) {
        return <>{children}</>;
    } else {
        return <Navigate to="/login" />;
    }
}
