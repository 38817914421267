// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { FormControl, FormControlProps, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ReactNode, useCallback } from 'react';

export interface Option {
    label: string;
    value: string;
    formatLabel?(label: string): ReactNode;
}

interface SelectorProps {
    className?: string;
    disabled?: boolean;
    label: string;
    options: Option[];
    value: string;
    onChange(value: string): void;
}

export default function Selector(props: SelectorProps) {
    const { className, label, value, disabled, options, onChange } = props;

    const handleChange = useCallback(
        (event: SelectChangeEvent) => {
            onChange(event.target.value);
        },
        [onChange],
    );

    return (
        <FormControl className={className} sx={{ minWidth: 120 }} size="small" disabled={disabled}>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                label={label}
                onChange={handleChange}
            >
                {
                    options
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.formatLabel ? option.formatLabel(option.label) : option.label}
                            </MenuItem>
                        ))
                }
            </Select>
        </FormControl>
    );
}
