// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Experimental_CssVarsProvider as CssVarsProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ErrorHandler from './components/ErrorHandler';
import ProtectedPage from './components/ProtectedPage';
import NotificationController from './controllers/NotificationController';
import ServicesController from './controllers/ServicesController';
import SessionController from './controllers/SessionController';
import routes from './router/routes';
import theme from './theme';


function App() {
    return (
        <StyledEngineProvider injectFirst>
            <CssVarsProvider theme={theme}>
                <ErrorHandler>
                    <NotificationController>
                        <SessionController>
                            <ServicesController>
                                <BrowserRouter>
                                    <Routes>
                                        {routes.map(route => (
                                            <Route
                                                key={route.path}
                                                path={route.path}
                                                element={(
                                                    route.protect
                                                        ? <ProtectedPage><route.component /></ ProtectedPage>
                                                        : <route.component />
                                                )}
                                            />
                                        ))}
                                    </Routes>
                                </BrowserRouter>
                            </ServicesController>
                        </SessionController>
                    </NotificationController>
                </ErrorHandler>
            </CssVarsProvider>
        </StyledEngineProvider>
    );
}

export default App;
