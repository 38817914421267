// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

export function capitalize(text: string) {
    if (text.length > 0) {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    } else {
        return text;
    }
}

export function plural(count: number, singular: string, plural?: string) {
    if (count === 1) {
        return singular;
    }

    return plural ? plural : `${singular}s`;
}

export function toTitleCase(text: string) {
    const words = text.split(' ');
    const titleCaseWords = words.map(word => capitalize(word));

    return titleCaseWords.join(' ');
}
