// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import clsx from 'clsx';
import { ReactNode } from 'react';
import { ReactComponent as InterWind } from '../assets/interwind.svg';
import styles from '../styles/Loading.module.css';

interface LoadingProps {
    className?: string;
    feedback?: ReactNode;
}

export default function Loading(props: LoadingProps) {
    const { feedback, className } = props;
    return (
        <div className={clsx(styles.container, className)}>
            <InterWind className={styles.loading} />
            {feedback}
        </div>
    );
}
