// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { useContext } from 'react';
import SessionContext from '../contexts/SessionContext';

export function useSessionContext() {
    const context = useContext(SessionContext);

    if (context === undefined) {
        throw new Error('useSessionContext must be used within a SessionController');
    }

    return context;
}

export function useSessionContextLoggedIn() {
    try {
        const context = useSessionContext();

        if (!context.loggedIn) {
            throw new Error('User is not logged in');
        }

        return context;
    } catch (e) {
        throw e;
    }
}
