// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import useAsyncEffect from '../hooks/useAsyncEffect';
import { useSessionContext } from '../hooks/useSessionContext';
import { SessionLoggedOut } from '../models/Session';

export default function SessionAuth() {
    const sessionContext = useSessionContext() as SessionLoggedOut;
    const navigate = useNavigate();

    const loginWithGitHub = useCallback(
        async () => {
            const searchParams = new URLSearchParams(window.location.search);
            const code = searchParams.get('code');
            const state = searchParams.get('state');

            if (!code) {
                throw new Error('Parameter "code" is missing');
            }

            if (!state) {
                throw new Error('Parameter "state" is missing');
            }

            return sessionContext.login.withGitHub(code, state);
        },
        [sessionContext.login],
    );

    useAsyncEffect(
        async () => {
            try {
                const codeHost = window.location.pathname.split('/')[2];

                if (!codeHost) {
                    throw new Error('Invalid authentication parameters');
                }

                switch (codeHost) {
                    case 'github':
                        await loginWithGitHub();
                        break;
                    default:
                        throw new Error(`Invalid authentication host: ${codeHost}`);
                }

                navigate('/');
            } catch (e: any) {
                console.error(e);
            }
        },
        [],
    );

    return <Loading />;
}
