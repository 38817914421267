// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { ChangeEvent, MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import styles from '../styles/EnhancedTable.module.css';
import { capitalize } from '../utils/text';

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<T>(order: Order, orderBy: keyof T): (a: T, b: T,) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

interface EnhancedTableHeadCell<T> {
    disablePadding: boolean;
    id: keyof T;
    label: string;
    numeric: boolean;
    sortable?: boolean;
    formatLabel?(label: T): ReactNode;
}

interface EnhancedTableHeadProps<T> {
    headCells: readonly EnhancedTableHeadCell<T>[];
    order: Order;
    orderBy: keyof T;
    onRequestSort: (event: MouseEvent<unknown>, property: keyof T) => void;
}

function EnhancedTableHead<T>(props: EnhancedTableHeadProps<T>) {
    const { headCells, order, orderBy } = props;
    const { onRequestSort } = props;

    const createSortHandler = useCallback(
        (property: keyof T) => (event: MouseEvent<unknown>) => {
            onRequestSort(event, property);
        },
        [onRequestSort],
    );

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    const isActive = orderBy === headCell.id;
                    return (
                        <TableCell
                            className={styles.tableHeadCell}
                            key={headCell.id as string}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={isActive ? order : false}
                        >
                            {!headCell.sortable && headCell.label}
                            {headCell.sortable && (
                                <TableSortLabel
                                    active={isActive}
                                    direction={isActive ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                    classes={{ active: styles.tableHeadCellLabelActive }}
                                >
                                    {headCell.label}
                                    {isActive && (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    )}
                                </TableSortLabel>
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

export interface EnhancedTableProps<T> {
    defaultOrderBy: keyof T;
    heads: readonly EnhancedTableHeadCell<T>[];
    highlightedRow?: T;
    noDataLabel?: string;
    onRowClick?(row: T): void;
    rows: readonly T[];
    itemType: string;
}

export default function EnhancedTable<T extends { id: string }>(props: EnhancedTableProps<T>) {
    const { heads, itemType, rows, noDataLabel, defaultOrderBy, highlightedRow, onRowClick } = props;
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<keyof T>(defaultOrderBy);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = useMemo(
        () => page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0,
        [page, rowsPerPage, rows],
    );

    const visibleRows = useMemo(
        () => rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .sort(getComparator(order, orderBy)),
        [order, orderBy, page, rows, rowsPerPage],
    );

    const handleRequestSort = useCallback(
        (_: any, property: keyof T) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        },
        [order, orderBy],
    );

    const handleClick = useCallback(
        (_: any, clickedRow: T) => {
            if (onRowClick) {
                onRowClick(clickedRow);
            }
        },
        [onRowClick],
    );

    const handleChangePage = useCallback(
        (_: any, newPage: number) => setPage(newPage),
        [],
    );

    const handleChangeRowsPerPage = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        },
        [],
    );

    return (
        <TableContainer component={Paper}>
            <TableContainer>
                <Table>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={heads}
                    />
                    <TableBody>
                        {visibleRows.map((row) => {
                            const isItemSelected = highlightedRow?.id === row.id;
                            return (
                                <TableRow
                                    onClick={(event) => handleClick(event, row)}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                >
                                    {
                                        heads.map((head, index) => (
                                            <TableCell
                                                key={index}
                                                component={index === 0 ? 'th' : 'td'}
                                                align={head.numeric ? 'right' : 'left'}
                                            >
                                                {head.formatLabel ? head.formatLabel(row) : `${row[head.id]}`}
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 - emptyRows }}>
                                <TableCell colSpan={7} />
                            </TableRow>
                        )}
                        {noDataLabel && !visibleRows.length && (
                            <TableRow style={{ height: 53 - emptyRows }}>
                                <TableCell colSpan={7}>
                                    <Typography className={styles.noDataLabel} variant="body2">
                                        {noDataLabel}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={`${capitalize(itemType)} per page`}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
}
