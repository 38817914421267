// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

export const routePaths = {
    home: '/',
    login: '/login',
    onboarding: '/onboarding',
    signin: {
        github: '/auth/github/signin',
    },
    register: '/register',
    repositories: '/repositories',
    issues: '/issues',
    plan: '/plan',
} as const;
