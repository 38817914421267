// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Link, Paper, Typography } from '@mui/material';
import styles from '../styles/Footer.module.css';

export default function Footer() {
    return (
        <Paper className={styles.container} variant="outlined" component="footer" square>
            <div className={styles.links}>
                <Link
                    target="_blank"
                    rel="noreferrer"
                    underline="none"
                    href="https://reviewpad.com/terms-and-conditions"
                >
                    <Typography variant="caption">
                        Terms and Conditions
                    </Typography>
                </Link>
                <Link
                    target="_blank"
                    rel="noreferrer"
                    underline="none"
                    href="https://docs.reviewpad.com"
                >
                    <Typography variant="caption">
                        Documentation
                    </Typography>
                </Link>
                <Link
                    target="_blank"
                    rel="noreferrer"
                    underline="none"
                    href="https://reviewpad.com/discord"
                >
                    <Typography variant="caption">
                        Discord
                    </Typography>
                </Link>
            </div>
            <Typography variant="caption">
                &copy; Copyright {new Date().getFullYear()} Reviewpad
            </Typography>
        </Paper>
    );
}
