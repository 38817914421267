// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Divider, Paper } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Issues from '../components/Issues';
import Loading from '../components/Loading';
import PlanManagement from '../components/PlanManagement';
import Sidebar from '../components/Sidebar';
import UnexpectedError from '../components/UnexpectedError';
import { routePaths } from '../constants/routes';
import { useNotificationContext } from '../hooks/notificationHook';
import { useParams } from '../hooks/paramsHook';
import { useAppService, useOrganizationService } from '../hooks/serviceHooks';
import useAsyncEffect from '../hooks/useAsyncEffect';
import { useSessionContextLoggedIn } from '../hooks/useSessionContext';
import { AppDetails } from '../models/App';
import Organization from '../models/Organization';
import Repository from '../models/Repository';
import styles from '../styles/Dashboard.module.css';

export default function Dashboard() {
    const { setParam, params } = useParams();
    const appService = useAppService();
    const navigate = useNavigate();
    const notification = useNotificationContext();
    const organizationService = useOrganizationService();
    const sessionContext = useSessionContextLoggedIn();
    const [error, setError] = useState<Error | undefined>();
    const [isLoadingRepositories, setIsLoadingRepositories] = useState(true);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<Organization>();
    const [selectedOrganizationRepositories, setSelectedOrganizationRepositories] = useState<Repository[]>([]);
    const [appDetails, setAppDetails] = useState<AppDetails>();

    const handleChangeOrganization = useCallback(
        (_event: React.SyntheticEvent<Element, Event>, value: Organization | null) => {
            if (!value) {
                return;
            }

            setSelectedOrganization(value);
            setParam('organization', value.name);
        },
        [setParam],
    );

    useEffect(
        () => {
            if (window.location.pathname === '/') {
                navigate(routePaths.issues);
            }
        },
        [navigate],
    );

    // Load organizations and app details for the first time.
    // If there are no organizations, redirect to the app installation page.
    useAsyncEffect(
        async () => {
            try {
                const [organizations, appDetails] = await Promise.all([
                    organizationService.getOrganizations(),
                    appService.getAppDetails('github')
                ]);

                if (!organizations.length) {
                    // When there are no organizations,
                    // it means that the user has not installed the app yet.
                    window.location.href = appDetails.installation_url;
                    return;
                }

                setOrganizations(organizations);
                setAppDetails(appDetails);
            } catch (error) {
                notification.error('Error loading organizations');
                console.error(error);
            }
        },
        []
    );

    // Select the organization from the URL params.
    // If there is no organization in the URL params, select the first one.
    useEffect(
        () => {
            if (!organizations.length) {
                return;
            }

            if (params.has('organization')) {
                const organization = organizations.find(organization => organization.name === params.get('organization'));

                if (organization) {
                    setSelectedOrganization(organization);
                    return;
                }
            }

            setSelectedOrganization(organizations[0]);
            setParam('organization', organizations[0].name);
        },
        [organizations, notification, setParam, params],
    );

    // Handle selected organization change.
    useAsyncEffect(
        async () => {
            if (!selectedOrganization) {
                return;
            }

            try {
                setIsLoadingRepositories(true);

                // If the selected organization is not the current one,
                // refresh the access token.
                if (selectedOrganization.id !== sessionContext.organizationId) {
                    await sessionContext.refreshAccessToken(selectedOrganization.user_code_host_id, selectedOrganization.id);
                    return;
                }

                const repositories = await organizationService.getRepositories(
                    selectedOrganization.id,
                    selectedOrganization.name,
                    selectedOrganization.type,
                    selectedOrganization.user_code_host_id,
                    selectedOrganization.installation_id,
                );

                setSelectedOrganizationRepositories(repositories);
                setIsLoadingRepositories(false);
            } catch (error) {
                setError(error as any);
                console.error(error);
            }
        },
        [selectedOrganization, sessionContext.organizationId],
    );

    if (error) {
        return <UnexpectedError />;
    }

    if (!selectedOrganization || !appDetails) {
        return <Loading />;
    }

    return (
        <Paper className={styles.container}>
            <Header
                installationUrl={appDetails.installation_url}
                organizations={organizations}
                selectedOrganization={selectedOrganization}
                onChangeOrganization={handleChangeOrganization}
            />
            <main className={styles.main}>
                <Sidebar />
                <Divider orientation="vertical" flexItem />
                <Routes>
                    <Route
                        path={routePaths.plan}
                        element={<PlanManagement organization={selectedOrganization} />}
                    />
                    <Route
                        path={routePaths.issues}
                        element={isLoadingRepositories ? <Loading /> :
                            <Issues
                                organization={selectedOrganization}
                                repositories={selectedOrganizationRepositories}
                            />
                        }
                    />
                </Routes>
            </main>
            <Footer />
        </Paper>
    );
}
