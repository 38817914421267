// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

export default extendTheme({
    typography: {
        fontFamily: 'Manrope, sans-serif',
    },
});
