// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Chip } from '@mui/material';
import { useMemo } from 'react';
import styles from '../styles/IssueSeverityLabel.module.css';

interface IssueSeverityLabelProps {
    severity: string;
}

export default function IssueSeverityLabel(props: IssueSeverityLabelProps) {
    const { severity } = props;

    const color = useMemo(
        () => {
            switch (severity) {
                case 'high':
                    return 'error';
                case 'medium':
                    return 'warning';
                case 'low':
                    return 'info';
            }
        },
        [severity],
    );

    return (
        <Chip
            className={styles.chip}
            label={severity.charAt(0).toUpperCase() + severity.slice(1)}
            color={color}
            size="small"
        />
    );
}
