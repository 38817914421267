// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { routePaths } from '../constants/routes';
import { RouteConfig } from '../models/RouteConfig';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login';
import SessionAuth from '../pages/SessionAuth';

const routes: readonly RouteConfig[] = [
    {
        path: routePaths.login,
        component: Login,
    },
    {
        path: routePaths.signin.github,
        component: SessionAuth,
    },
    {
        path: '/*',
        protect: true,
        component: Dashboard,
    },
];

export default routes;
