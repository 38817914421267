// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { SubscriptionTeam } from '../models/Subscription';
import styles from '../styles/TeamPlanEdition.module.css';
import TeamPlanStatus from './TeamPlanStatus';
import TeamPlanSummary from './TeamPlanSummary';

interface TeamPlanEditionProps {
    isUserAdmin: boolean;
    subscription: SubscriptionTeam;
    defaultTotalSeats: number;
    onAutoRenewChange(isAutoRenewEnabled: boolean): void;
    onSubscriptionCheckout(totalSeats: number): void;
}

export default function TeamPlanEdition(props: TeamPlanEditionProps) {
    const { isUserAdmin, subscription, defaultTotalSeats } = props;
    const { onAutoRenewChange, onSubscriptionCheckout } = props;

    return (
        <div className={styles.container}>
            <TeamPlanSummary subscription={subscription} />
            <TeamPlanStatus
                isUserAdmin={isUserAdmin}
                subscription={subscription}
                defaultTotalSeats={defaultTotalSeats}
                onAutoRenewChange={onAutoRenewChange}
                onSubscriptionCheckout={onSubscriptionCheckout}
            />
        </div>
    );
}
