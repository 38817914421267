// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from '../styles/Title.module.css';

interface TitleProps {
    children: ReactNode;
    className?: string;
}

export default function Title(props: TitleProps) {
    const { className, children } = props;
    return (
        <Typography className={clsx(styles.title, className)} variant="h4" component="h1">
            {children}
        </Typography>
    );
}
