// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { useContext } from 'react';
import ServicesContext from '../contexts/ServicesContext';

function useService() {
    return useContext(ServicesContext);
}

export function useAppService() {
    return useService().app;
}

export function useOrganizationService() {
    return useService().organization;
}
