// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { createContext } from 'react';
import Session from '../models/Session';

export const SessionContext = createContext({} as Session);

export default SessionContext;
