// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import {
    CircularProgress,
    Drawer, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useColorScheme
} from '@mui/material';
import { Buffer } from 'buffer';
import { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import anOldHope from 'react-syntax-highlighter/dist/cjs/styles/hljs/an-old-hope';
import monoBlue from 'react-syntax-highlighter/dist/cjs/styles/hljs/mono-blue';
import remarkGfm from 'remark-gfm';
import useAsyncEffect from '../hooks/useAsyncEffect';
import styles from '../styles/IssueHelperDrawer.module.css';

interface ErrorFallbackProps {
    checkKey: string;
}

function ErrorFallback(props: ErrorFallbackProps) {
    const { checkKey } = props;
    return (
        <div className={styles.errorFallbackContainer}>
            <ErrorIcon />
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                Error loading check description for {checkKey}
            </Typography>
            <Typography variant="body1">
                Please try again later.
            </Typography>
        </div>
    );
}

interface CheckDescriptionProps {
    description: string;
}

function CheckDescription(props: CheckDescriptionProps) {
    const { description } = props;
    const { mode } = useColorScheme();
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = useCallback(
        () => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000);
        },
        [],
    );

    return (
        <ReactMarkdown
            remarkPlugins={[[remarkGfm]]}
            components={{
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline && match
                        ? (
                            <div className={styles.codeBlock}>
                                <CopyToClipboard
                                    onCopy={handleCopy}
                                    text={String(children).replace(/\n$/, '')}
                                >
                                    <Tooltip title='Copied!' open={isCopied} arrow>
                                        <IconButton
                                            type="button"
                                            aria-label="Copy to Clipboard Button"
                                            className={styles.copyButton}
                                        >
                                            {isCopied ? <DoneIcon /> : <ContentCopyIcon />}
                                        </IconButton>
                                    </Tooltip>
                                </CopyToClipboard>
                                <SyntaxHighlighter
                                    style={mode === 'dark' ? anOldHope : monoBlue}
                                    language={match[1]}
                                    PreTag="div"
                                    customStyle={{ padding: '1rem', borderRadius: '0.5rem' }}
                                >
                                    {String(children).replace(/\n$/, '')}
                                </SyntaxHighlighter>
                            </div>
                        )
                        : (
                            <code className={styles.inlineCode} data-dark-mode-on={mode === 'dark'}>
                                {children}
                            </code>
                        );
                },
                table: ({ children }) => (
                    <TableContainer component={Paper} sx={{ m: '1rem 0' }}>
                        <Table size="small">{children}</Table>
                    </TableContainer>
                ),
                tbody: ({ children }) => (
                    <TableBody>
                        {children}
                    </TableBody>
                ),
                thead: ({ children }) => (
                    <TableHead>
                        {children}
                    </TableHead>
                ),
                tr: ({ children }) => (
                    <TableRow>
                        {children}
                    </TableRow>
                ),
                td: ({ children }) => (
                    <TableCell sx={{ p: '1rem' }}>
                        <Typography>{children}</Typography>
                    </TableCell>
                ),
                th: ({ children }) => (
                    <TableCell sx={{ p: '1rem' }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                            {children}
                        </Typography>
                    </TableCell>
                ),
                p: ({ children }) => (
                    <Typography sx={{ p: '1rem' }}>
                        {children}
                    </Typography>
                ),
            }}
        >
            {description}
        </ReactMarkdown>
    );
}

interface IssueHelperDrawerProps {
    drawerOpen: boolean;
    onDrawerClose(): void;
    selectedIssueKey?: string;
}

export default function IssueHelperDrawer(props: IssueHelperDrawerProps) {
    const { drawerOpen, selectedIssueKey, onDrawerClose } = props;
    const [error, setError] = useState<Error | null>(null);
    const [checkDescription, setCheckDescription] = useState<string>('');

    useAsyncEffect(
        async () => {
            if (!selectedIssueKey) {
                return;
            }

            try {
                setCheckDescription('');

                const checkDescription = await
                    fetch(`https://api.github.com/repos/reviewpad/docs/contents/versioned_docs/version-v4/checks/${selectedIssueKey}.mdx`, {
                        headers: {
                            'Authorization': `Bearer ${process.env.REACT_APP_GITHUB_ACCESS_TOKEN}`,
                        },
                    })
                        .then(response => response.json())
                        .then(data => Buffer.from(data.content, 'base64').toString('utf-8'));

                setError(null);
                setCheckDescription(checkDescription);
            } catch (error: any) {
                console.error(error);
                setError(error);
            }
        },
        [selectedIssueKey],
    );

    return (
        <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={onDrawerClose}
            PaperProps={{ className: styles.helperDrawer }}
        >
            {selectedIssueKey && error && <ErrorFallback checkKey={selectedIssueKey} />}
            {selectedIssueKey && !error && (
                checkDescription
                    ? <CheckDescription description={checkDescription} />
                    : <CircularProgress />
            )}
        </Drawer>
    );
}
