// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Card, CardContent, CardHeader, Typography } from '@mui/material';

export default function OpenSourcePlanCard() {
    return (
        <Card variant="outlined">
            <CardHeader title={<strong>Open Source Plan</strong>} />
            <CardContent sx={{ paddingTop: 0, lineHeight: 1.5 }}>
                <ul>
                    <li><Typography>Unlimited public repositories</Typography></li>
                    <li><Typography>Unlimited number of contributors on public repositories</Typography></li>
                    <li><Typography>All-inclusive Reviewpad features</Typography></li>
                    <ul>
                        <li><Typography>AI assistant for code reviewers</Typography></li>
                        <li><Typography>Advanced code reviewer assignments</Typography></li>
                        <li><Typography>Advanced merge policy</Typography></li>
                        <li><Typography>Automated pull request workflows</Typography></li>
                    </ul>
                </ul>
                <Typography sx={{ marginTop: 4 }}>
                    We&apos;re delighted to grant you full access to Reviewpad&apos;s extensive feature suite, enabling us to provide comprehensive support for your open-source contributions. We kindly request your assistance in promoting Reviewpad by sharing your experiences and mentioning us in your tweets and conversations.
                </Typography>
            </CardContent>
        </Card>
    );
}
