// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { useEffect } from 'react';

type Maybe<T> = T | undefined;

type Result = void | (() => Maybe<void>);

export default function useAsyncEffect(asyncEffect: () => Promise<Result>, dependencies: readonly any[]) {
    useEffect(
        () => {
            let result: Result;

            asyncEffect().then(r => result = r);

            return () => {
                if (typeof result === 'function') {
                    result();
                }
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dependencies,
    );
}
