// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

export class AsyncRequest<T> {
    accept!: (value: T) => void;
    reject!: (error: any) => void;

    promise = new Promise<T>((accept, reject) => {
        this.accept = accept;
        this.reject = reject;
    });
}

export function throttleAsyncRequest<A extends any[], R>(action: (...args: A) => Promise<R>) {
    let request: AsyncRequest<R> | null = null;

    return async (...args: A) => {
        if (request) return request.promise;

        request = new AsyncRequest();

        try {
            request.accept(await action(...args));
        } catch (e) {
            request.reject(e);
        } finally {
            const current = request.promise;
            request = null;
            return current;
        }
    };
}

/**
 * Builds a debounce version of the provided action.
 *
 * @param action The action to execute when the delay times out.
 * @param delay Number of milliseconds to wait after each call.
 *
 * @returns The debounce version.
 */
export function debounce(action: (...args: any) => void, delay: number) {
    let timer: any = null;

    return (...args: any) => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => action(...args), delay);
    };
}

