// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import Session from '../models/Session';

type Body = Record<string, any>;

export default class ApiClient {
    private baseUrl: string;
    private session: Session;

    constructor({ baseUrl, session }: { baseUrl: string; session: Session; }) {
        this.baseUrl = baseUrl;
        this.session = session;
    }

    get(path?: string) {
        return this.retrieve('GET', path);
    }

    head(path: string) {
        return this.retrieve('HEAD', path);
    }

    delete(path: string, body?: Body) {
        return this.send('DELETE', path, body);
    }

    post(path: string, body?: Body) {
        return this.send('POST', path, body);
    }

    patch(path: string, body?: Body) {
        return this.send('PATCH', path, body);
    }

    put(path: string, body?: Body) {
        return this.send('PUT', path, body);
    }

    private url(path?: string) {
        const pathname = path ? path.charAt(0) === '?' ? path : `/${path}` : '';
        return `${this.baseUrl}${pathname}`;
    }

    private retrieve(method: string, path?: string) {
        return this.fetch(
            this.url(path),
            { method },
        );
    }

    private send(method: string, path: string, body?: Body) {
        if (!body) {
            return this.retrieve(method, path);
        }

        return this.fetch(
            this.url(path),
            {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            }
        );
    }

    private async fetch(input: RequestInfo, init: RequestInit = {}) {
        if (!this.session.loggedIn) {
            throw new Error('Attempting to access API while logged out');
        }

        const headers = new Headers(init.headers);
        headers.append('Accept', 'application/json');
        headers.append('Authorization', `Bearer ${this.session.accessToken}`);

        const response = await fetch(input, { ...init, headers });

        // Token has expired
        if (response.status === 401) {
            const newAccessToken = await this.session.refreshAccessToken();
            headers.set('Authorization', `Bearer ${newAccessToken}`);

            return fetch(input, { ...init, headers });
        }

        return response;
    }
}
