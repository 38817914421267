// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Button, Link, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { routePaths } from '../constants/routes';
import styles from '../styles/UnexpectedError.module.css';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

interface UnexpectedErrorProps {
    children?: ReactNode;
}

export default function UnexpectedError(props: UnexpectedErrorProps) {
    const { children } = props;
    return (
        <div className={styles.container}>
            <Typography variant="h1">Oops.</Typography>
            <Typography variant="h5" component="h2" color="textSecondary">
                Something went wrong!
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                Please try again. If the problem persists, contact us at <Link href="mailto:support@reviewpad.com">support@reviewpad.com</Link>
            </Typography>
            {children}
            {!children && (
                <Button
                    className={styles.home}
                    variant="contained"
                    href={routePaths.home}
                >
                    Go home
                </Button>
            )}
        </div>
    );
}
