// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Alert, AlertProps, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from '@mui/material';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import NotificationContext from '../contexts/NotificationContext';
import { NotificationConfirmSettings, NotificationSettings } from '../models/Notification';
import { AsyncRequest } from '../utils/async';

interface DialogSettings extends NotificationConfirmSettings {
    request: AsyncRequest<boolean>;
}

interface AlertSettings extends NotificationSettings {
    open: boolean;
    severity?: AlertProps['severity'];
    message?: string;
    autoHideDuration?: number;
}

interface NotificationControllerProps {
    children: ReactNode;
}

export default function NotificationController(props: NotificationControllerProps) {
    const { children } = props;
    const [alertSettings, setAlertSettings] = useState<AlertSettings>({ open: false });
    const [dialogSettings, setDialogSettings] = useState<DialogSettings>();

    const notify = useCallback(
        (severity: AlertProps['severity'], message: string, settings?: NotificationSettings) => {
            setAlertSettings({
                open: true,
                severity,
                message,
                autoHideDuration: settings?.autoHideDuration || 6000,
            });
        },
        [],
    );

    const error = useCallback((message: string, settings?: NotificationSettings) => notify('error', message, settings), [notify]);
    const warning = useCallback((message: string, settings?: NotificationSettings) => notify('warning', message, settings), [notify]);
    const success = useCallback((message: string, settings?: NotificationSettings) => notify('success', message, settings), [notify]);
    const info = useCallback((message: string, settings?: NotificationSettings) => notify('info', message, settings), [notify]);

    const confirm = useCallback(
        (settings: NotificationConfirmSettings) => {
            if (dialogSettings) {
                dialogSettings.request.reject(new Error('A new dialog was fired'));
            }

            const request = new AsyncRequest<boolean>();
            setDialogSettings({ ...settings, request });

            return request.promise;
        },
        [dialogSettings]
    );

    const handleDialogConfirm = useCallback(
        () => {
            dialogSettings?.request.accept(true);
            setDialogSettings(undefined);
        },
        [dialogSettings],
    );

    const handleDialogCancel = useCallback(
        () => {
            dialogSettings?.request.accept(false);
            setDialogSettings(undefined);
        },
        [dialogSettings],
    );

    const handleAlertClose = useCallback(
        () => {
            setAlertSettings({ open: false });
        },
        [],
    );

    const value = useMemo(
        () => ({
            error,
            warning,
            success,
            info,
            confirm,
        }),
        [error, warning, success, info, confirm],
    );

    return (
        <NotificationContext.Provider value={value}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={alertSettings.open}
                autoHideDuration={alertSettings.autoHideDuration}
                onClose={handleAlertClose}
                sx={{ maxWidth: 600 }}
            >
                <Alert onClose={handleAlertClose} severity={alertSettings.severity}>
                    {alertSettings.message}
                </Alert>
            </Snackbar>
            <Dialog
                open={Boolean(dialogSettings)}
                onClose={handleDialogCancel}
            >
                <DialogTitle>{dialogSettings?.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogSettings?.description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCancel}>
                        {dialogSettings?.cancelLabel || 'Cancel'}
                    </Button>
                    <Button onClick={handleDialogConfirm} autoFocus>
                        {dialogSettings?.confirmLabel || 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
            {children}
        </NotificationContext.Provider>
    );
}
