// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Container } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from '../styles/PageContainer.module.css';

interface PageContainerProps {
    children: ReactNode;
    className?: string;
}

export default function PageContainer(props: PageContainerProps) {
    const { className, children } = props;
    return (
        <Container className={clsx(styles.container, className)} maxWidth={false}>
            {children}
        </Container>
    );
}
