// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

const BASE_API_URL = process.env.REACT_APP_NEXUS_BASE_API_URL;

export default {
    endpoints: {
        auth: `${BASE_API_URL}/auth`,
        organization: `${BASE_API_URL}/user/organization`,
        subscription: `${BASE_API_URL}/user/subscription`,
        app: `${BASE_API_URL}/app`,
    }
};
