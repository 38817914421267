// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

interface SetParamOptions {
    noReplace?: boolean;
}

export function useParams() {
    const navigate = useNavigate();

    const params = useMemo(
        () => new URLSearchParams(window.location.search),
        [],
    );

    const setParam = useCallback(
        (key: string, value?: string, options?: SetParamOptions) => {
            if (value) {
                params.set(key, value);
            } else if (params.has(key)) {
                params.delete(key);
            }

            if (options?.noReplace) {
                navigate(`${window.location.pathname}?${params.toString()}`);
            } else {
                window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
            }
        },
        [navigate, params],
    );

    return {
        params,
        setParam,
    };
}
