// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import React, { ReactNode } from 'react';
import config from '../config';
import { routePaths } from '../constants/routes';
import SessionContext from '../contexts/SessionContext';
import Session, { SessionLoggedIn, SessionLoggedOut } from '../models/Session';
import User, { AuthenticatedUser } from '../models/User';
import { throttleAsyncRequest } from '../utils/async';

interface State {
    session?: Session;
}

interface SessionControllerProps {
    children: ReactNode;
}

class SessionController extends React.PureComponent<SessionControllerProps, State> {
    constructor(props: SessionControllerProps) {
        super(props);
        this.state = {};
        this.loginWithGitHub = this.loginWithGitHub.bind(this);
    }

    componentDidMount() {
        const sessionData = localStorage.getItem('session');
        if (sessionData) {
            this.setState({
                session: {
                    loggedIn: true,
                    ...JSON.parse(sessionData),
                    logout: this.logout,
                    refreshAccessToken: this.refreshAccessToken,
                }
            });
        } else {
            this.setState({
                session: {
                    loggedIn: false,
                    login: {
                        withGitHub: this.loginWithGitHub,
                    },
                }
            });
        }
    }

    private async loginWithGitHub(): Promise<void>;
    private async loginWithGitHub(code: string, state: string): Promise<User>;
    private async loginWithGitHub(code?: string, state?: string): Promise<User | void> {
        if (!code || !state) {
            const gitHubLoginRedirectUrl = await getGitHubAuthorizeUrl();
            const redirectUri = `${window.location.origin}${routePaths.signin.github}`;
            window.location.href = `${gitHubLoginRedirectUrl}&redirect_uri=${encodeURIComponent(redirectUri)}`;
            return;
        }

        const authenticatedUser = await loginWithGitHub(code, state);

        const session: SessionLoggedIn = {
            loggedIn: true,
            user: {
                id: authenticatedUser.id,
                name: authenticatedUser.name,
                avatarUrl: authenticatedUser.avatarUrl,
            },
            accessToken: authenticatedUser.accessToken,
            refreshToken: authenticatedUser.refreshToken,
            refreshAccessToken: this.refreshAccessToken,
            logout: this.logout,
            organizationId: authenticatedUser.organizationId,
            userCodeHostId: authenticatedUser.userCodeHostId,
        };

        localStorage.setItem('session', JSON.stringify(session));

        this.setState({ session });
    }

    private logout = () => {
        const session: SessionLoggedOut = {
            loggedIn: false,
            login: {
                withGitHub: this.loginWithGitHub,
            }
        };

        localStorage.removeItem('session');

        this.setState({ session });
    };

    // refreshAccessToken refreshes the access token for the current session.
    // The request is throttled to avoid multiple requests at the same time.
    // If userCodeHostId or organizationId are provided, they will be used instead of the ones in the current session.
    // userCodeHostId and organizationId are used to identify to which code host and organization the access token belongs.
    // This is needed for the backend to know to which organization the user is trying to access to.
    private refreshAccessToken = throttleAsyncRequest(async (userCodeHostId?: string, organizationId?: string) => {
        const { session } = this.state;

        if (session && session.loggedIn) {
            const urlSearchParams = new URLSearchParams({
                'user_code_host_id': userCodeHostId || session.userCodeHostId,
                'organization_id': organizationId || session.organizationId,
            });

            const response = await fetch(
                `${config.endpoints.auth}/token?${urlSearchParams}`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        userId: session.user.id,
                        refreshToken: session.refreshToken,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    }
                },
            );

            if (response.ok) {
                const result = await response.json();
                const accessToken = result.accessToken;
                const updatedSession = {
                    ...session,
                    userCodeHostId: userCodeHostId || session.userCodeHostId,
                    organizationId: organizationId || session.organizationId,
                    accessToken,
                };

                this.setState({ session: updatedSession });
                localStorage.setItem('session', JSON.stringify(updatedSession));

                return accessToken;
            } else {
                this.logout();
                throw new Error('Error on refresh session');
            }
        } else {
            throw new Error('User not logged in');
        }
    });

    render() {
        if (!this.state.session) {
            return null;
        }

        return (
            <SessionContext.Provider value={this.state.session}>
                {this.props.children}
            </SessionContext.Provider>
        );
    }
}

async function getGitHubAuthorizeUrl() {
    const response = await fetch(`${config.endpoints.auth}/github/authorize`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    });

    if (response.status !== 200) {
        throw new Error('Failed to get GitHub authorization URL');
    }

    const { url } = (await response.json());

    return url;
};

async function loginWithGitHub(code: string, state: string): Promise<AuthenticatedUser> {
    const response = await fetch(`${config.endpoints.auth}/github/signin`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code, state }),
    });

    return response.json();
}

export default SessionController;
